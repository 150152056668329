<template>
  <layout-default>
    <v-layout wrap align-center justify-center>
      <v-flex xs12 md6>
        <v-card style="max-width: 800px" v-if="releases && !$apollo.loading">
          <v-card-title
              class="headline font-weight-regular blue-grey white--text"
          >Diff release {{ baseRelease.name }} to {{ targetRelease.name }}
          </v-card-title>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-content
                  v-for="(release,i) in parsedReleases"
                  :key="i"
              >
                <template v-slot:header>
                  <div class="flex text-xs-left px-2">
                    <div class="font-weight-bold">Service</div>
                    {{ release.name }}
                  </div>
                  <div class="flex text-xs-left px-2">
                    <div class="font-weight-bold">{{ baseRelease.name }}</div>
                    {{ release.baseTag }}
                  </div>
                  <div class="flex text-xs-left px-2">
                    <div class="font-weight-bold">{{ targetRelease.name }}</div>
                    {{ release.targetTag }}
                  </div>
                  <div class="flex text-xs-left px-2">
                    <div class="font-weight-bold">Same</div>
                    <v-chip
                        small
                        :color="
                              release.baseTag === release.targetTag ? 'green' : 'red'
                            "
                    >{{
                        release.baseTag === release.targetTag ? "yep" : "nope"
                      }}
                    </v-chip
                    >
                  </div>
                </template>
                <v-card>
                  <v-card-text>
                    <template v-for="(diffLine, i) in diffs.split('\n')">
                      <div :key="i">{{ diffLine }}<br></div>
                    </template>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default.vue";
import LIST_RELEASES from "@/graphql/query/ReleasesList.gql";
import RELEASE_DIFF from "@/graphql/query/ReleaseDiff.gql";

export default {
  name: "diffRelease",
  components: {
    LayoutDefault,
  },
  props: ["baseId", "targetId"],
  // data props to component
  data: () => ({
    releases: [],
    loading: false,
    diffs: ''
  }),
  computed: {
    baseRelease() {
      return this.findRelease(this.baseId);
    },
    targetRelease() {
      return this.findRelease(this.targetId);
    },
    parsedReleases() {
      return this.baseRelease.tags.map(tag => ({
        name: tag.service.name,
        baseTag: tag.name,
        targetTag: this.findTagForService(this.targetRelease, tag.service.name).name
      }));
    },
  },
  methods: {
    findRelease(id) {
      return this.releases.find(x => x.id === id);
    },
    findTagForService(release, serviceName) {
      return release.tags.find(x => x.service.name === serviceName);
    },
  },
  apollo: {
    releases: {
      query: LIST_RELEASES,
      loadingKey: "loading",
      error(error) {
        console.error("We've got an error!", error);
        this.error = error;
      },
    },
    diffs: {
      query: RELEASE_DIFF,
      variables() {
        return {
          release1Id: this.baseId,
          release2Id: this.targetId
        }
      },
      loadingKey: "loading",
      error(error) {
        console.error("We've got an error!", error);
        this.error = error;
      },
    },
  },
};
</script>
